<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  label.custom-control-label {
    user-select: none;
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { OrbitSpinner } from 'epic-spinners';

import CopyableText from "@/components/CopyableText";
import CopyableInput from "@/components/CopyableInput";
import {get_acsrf_token} from "@/methods";
import {WebHookFlavor, WebHookPeerTrustState, WebHookPurpose, WebHookState} from "@/enums";

import {
  maxLength,
  minLength,
  required,
  url
} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";

export default {
  props: ['banlist_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    // eslint-disable-next-line vue/no-unused-components
    OrbitSpinner,
    // eslint-disable-next-line vue/no-unused-components
    CopyableInput
  },
  validations: {
    webhookForm: {
      url: {required, url},
      flavor: {required}
    }
  },
  computed: {
    ...mapGetters([
      'getUILanguage',
      'getDTLocale'
    ]),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/integrations`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.webhooks = data.webhooks;
          this.grants = data.grants;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    async toggleWebhookModal() {
      this.$refs.addWebHookModal.show();
    },
    async webhookFormUrlChanged() {
      this.$v.webhookForm.$touch();
      if(this.webhookForm.url.includes('https://discord.com/api/webhooks')) this.webhookForm.flavor = WebHookFlavor.DISCORD;
      else this.webhookForm.flavor = WebHookFlavor.CFTOOLS;
    },
    async newWebHook() {
      this.$v.webhookForm.$touch();
      if(this.$v.webhookForm.$error) return;

      this.creationInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_id: this.banlist_id,
          url: this.webhookForm.url,
          flavor: this.webhookForm.flavor
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/webhook`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.webhookForm = {
            url: null,
            flavor: null
          };
          this.$refs.addWebHookModal.hide();
          this.$swal({
            icon: 'success',
            text: this.$t('banmanager.view.integrations.webhooks.new.success')
          });
          await this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.creationInProgress = false;
    },
    //
    async webhookUsernameChange(webhookId, webhook) {
      let refTag = `webhookUsername:${webhookId}`;
      let e = this.$refs[refTag][0];
      let newUsername = e.value;
      let previousUsername = webhook.settings.username;
      webhook.settings.username = null;
      this.savingInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_id: this.banlist_id,
          webhook_id: webhookId,
          username: newUsername
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/webhook-update`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          webhook.settings.username = newUsername;
          e.classList.add('is-valid');
          setTimeout(() => {
            e.classList.remove('is-valid');
          }, 750);
        } else {
          webhook.settings.username = previousUsername;
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        e.classList.add('is-invalid');
        setTimeout(() => {
          e.classList.remove('is-invalid');
        }, 750);
      }
      this.savingInProgress = false;
    },
    async webhookAvatarChange(webhookId, webhook) {
      let refTag = `webhookAvatar:${webhookId}`;
      let e = this.$refs[refTag][0];
      let newAvatar = e.value;
      let previousAvatar = webhook.settings.avatar_url;
      webhook.settings.avatar_url = null;
      this.savingInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_id: this.banlist_id,
          webhook_id: webhookId,
          avatar_url: newAvatar
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/webhook-update`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          webhook.settings.avatar_url = newAvatar;
          e.classList.add('is-valid');
          setTimeout(() => {
            e.classList.remove('is-valid');
          }, 750);
        } else {
          webhook.settings.avatar_url = previousAvatar;
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        e.classList.add('is-invalid');
        setTimeout(() => {
          e.classList.remove('is-invalid');
        }, 750);
      }
      this.savingInProgress = false;
    },
    async webhookDelete(webhookId) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_id: this.banlist_id,
          webhook_id: webhookId
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/webhook-delete`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.getData();
          this.$swal({
            icon: 'success',
            text: this.$t('banmanager.view.webhooks.grants.list.delete_success')
          });
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async grantDelete(grantId) {
      this.savingInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          server_id: this.server_id,
          grant_id: grantId
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/grant-delete`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.getData();
          this.$swal({
            icon: 'success',
            text: this.$t('banmanager.view.integrations.grants.list.delete.success')
          });
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
      this.savingInProgress = false;
    },
    async webhookReset(webhookId) {
      this.savingInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_id: this.banlist_id,
          webhook_id: webhookId
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/webhook-reset`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.getData();
          this.$toast.success(this.$t('banmanager.view.integrations.webhooks.list.reset.success'));
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.title'));
      }
      this.savingInProgress = false;
    },
    async webhookEventsSave(webhookId) {
      let events = [];
      let children = this.$el.querySelectorAll(`.collector-${webhookId}`);
      children.forEach((e) => {
        e = e.firstChild;
        if(e.checked) {
          events.push(e.name);
        }
      });
      this.savingInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_id: this.banlist_id,
          webhook_id: webhookId,
          events: events
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/webhook-events`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.getData();
          this.$toast.success(this.$t('banmanager.view.integrations.webhooks.list.save.success'));
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.title'));
      }
      this.savingInProgress = false;
    },
    async webhookTemplatesSave(webhookId) {
      this.savingInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_id: this.banlist_id,
          webhook_id: webhookId,
          templates: this.featuredWebhookTemplates
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/webhook-templates`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.getData();
          this.$toast.success(this.$t('banmanager.view.integrations.webhooks.list.save.success'));
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.title'));
      }
      this.savingInProgress = false;
    },
    async webhookStrings(webhookId) {
      let webhook = null;
      this.webhooks.forEach((w) => {
        if(w.id === webhookId) {
          webhook = w;
          return;
        }
      });
      if(!webhook) return;
      this.featuredWebhookTemplates = webhook.settings.templates;
      this.featuredWebhookId = webhook.id;
      this.$refs.webhookTemplatesModal.show();
    }
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      WebHookState:WebHookState,
      WebHookFlavor:WebHookFlavor,
      WebHookPeerTrustState:WebHookPeerTrustState,
      ready: false,
      error: false,
      savingInProgress: false,
      creationInProgress: false,
      grants: [],
      webhooks: [],
      webhookForm: {
        url: null,
        flavor: null
      },
      featuredWebhookId: null,
      featuredWebhookTemplates: {}
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-9 col-sm-12">
          <div v-if="webhooks.length">
            <div role="tablist">
              <b-card no-body class="shadow-none settings-collapse" v-for="webhook in webhooks" :key="webhook.id">
                <a v-b-toggle="webhook.id" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <div class="d-inline-block">
                      <h3 class="m-0 text-code text-uppercase">
                        <template v-if="webhook.state === WebHookState.CREATED">
                          <button class="btn btn-sm btn-light" v-b-tooltip.hover :title="$t('banmanager.view.integrations.webhooks.list.status.created')">
                            <i class="fad fa-shield-check font-size-16 text-info"></i>
                          </button>
                        </template>
                        <template v-else-if="webhook.state === WebHookState.VERIFYING">
                          <button class="btn btn-sm btn-light" v-b-tooltip.hover :title="$t('banmanager.view.integrations.webhooks.list.status.verifying')">
                            <i class="fad fa-shield-check font-size-16 text-primary"></i>
                          </button>
                        </template>
                        <template v-else-if="webhook.state === WebHookState.VERIFY_ERROR">
                          <button class="btn btn-sm btn-light" v-b-tooltip.hover :title="$t('banmanager.view.integrations.webhooks.list.status.verify_error')">
                            <i class="fad fa-shield-check font-size-16 text-danger"></i>
                          </button>
                        </template>
                        <template v-else-if="webhook.state === WebHookState.VERIFIED">
                          <button class="btn btn-sm btn-light" v-b-tooltip.hover :title="$t('banmanager.view.integrations.webhooks.list.status.verified')">
                            <i class="fad fa-shield-check font-size-16 text-success"></i>
                          </button>
                        </template>
                        <template v-else-if="webhook.state === WebHookState.PAUSED">
                          <button class="btn btn-sm btn-light" v-b-tooltip.hover :title="$t('banmanager.view.integrations.webhooks.list.status.paused')">
                            <i class="fad fa-shield-check font-size-16 text-warning"></i>
                          </button>
                        </template>
                        <template v-else-if="webhook.state === WebHookState.ERROR">
                          <button class="btn btn-sm btn-light" v-b-tooltip.hover :title="$t('banmanager.view.integrations.webhooks.list.status.error')">
                            <i class="fad fa-shield-check font-size-16 text-danger"></i>
                          </button>
                        </template>
                        {{webhook.uuid}}

                        <button class="btn btn-light btn-sm" v-if="webhook.flavor === WebHookFlavor.CFTOOLS" v-b-tooltip.hover title="Format: CFTools Cloud Hephaistos">
                          <img src="@/assets/images/logo-light.svg" alt height="24" />
                        </button>
                        <button class="btn btn-light btn-sm" v-else-if="webhook.flavor === WebHookFlavor.DISCORD" v-b-tooltip.hover title="Format: Discord WebHook">
                          <i class="fab fa-discord font-size-20"/>
                        </button>

                        <template v-if="webhook.trust === WebHookPeerTrustState.NOT_SECURE">
                          <span class="badge badge-warning text-black">
                            {{ $t('banmanager.view.integrations.webhooks.list.insecure') }}
                          </span>
                        </template>
                        <template v-else-if="webhook.trust === WebHookPeerTrustState.SSL_ERROR">
                          <span class="badge badge-warning text-black">
                            {{ $t('banmanager.view.integrations.webhooks.list.ssl_error') }}
                          </span>
                        </template>
                      </h3>
                      <!--
                      // TODO: Show TLD?
                      <a href="javascript: void(0);">
                        {{ webhook.url }}
                      </a>
                      -->
                    </div>
                    <div class="d-inline-block float-right bv-d-md-down-none">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('banmanager.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>

                <!-- General -->
                <b-collapse :id="webhook.id" accordion="webhooks" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-12">
                          <label>
                            {{ $t('banmanager.view.integrations.webhooks.new.url.label') }}
                          </label>
                          <CopyableInput
                              size="md"
                              :text="webhook.url"
                              :label="webhook.url"
                              class="disabled text-code"
                          />
                        </div>
                      </div>
                      <div class="row mt-2" v-if="webhook.flavor !== WebHookFlavor.DISCORD">
                        <div class="col-12">
                          <label>
                            {{ $t('banmanager.view.integrations.webhooks.list.secret') }}
                          </label>
                          <CopyableInput
                              size="md"
                              :text="webhook.secret"
                              :label="webhook.secret"
                              class="disabled text-code"
                          />
                        </div>
                      </div>
                      <div class="row mt-2" v-if="webhook.flavor === WebHookFlavor.DISCORD">
                        <div class="col-lg-4 col-sm-12">
                          <div class="form-group pb-0 mb-0">
                            <label>
                              {{ $t('banmanager.view.integrations.webhooks.list.username') }}
                            </label>
                            <input
                              :ref="'webhookUsername:'+webhook.id"
                              :value="webhook.settings.username || 'CFTools Cloud'"
                              type="text"
                              class="form-control"
                              v-on:change="webhookUsernameChange(webhook.id, webhook)"
                            />
                          </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                          <div class="form-group pb-0 mb-0">
                            <label>
                              {{ $t('banmanager.view.integrations.webhooks.list.avatar') }}
                            </label>
                            <input
                              :ref="'webhookAvatar:'+webhook.id"
                              :value="webhook.settings.avatar_url || ''"
                              type="text"
                              class="form-control"
                              v-on:change="webhookAvatarChange(webhook.id, webhook)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-3 col-sm-12">
                          <label>
                            {{ $t('banmanager.view.integrations.webhooks.list.creator') }}
                          </label>
                          <div>
                            <router-link target="_blank"  :to="{name: 'profile', params: {cftools_id: webhook.user.cftools_id}}">
                              <img
                                  :src="webhook.user.avatar"
                                  alt="profile picture"
                                  class="rounded-circle avatar-xs"
                              >
                              {{ webhook.user.display_name }}
                            </router-link>
                          </div>
                        </div>
                        <div class="col-lg-9 col-sm-12">
                          <!-- Settings & events -->
                          <div class="row">
                            <div class="col">
                              <label>
                                {{ $t('banmanager.view.integrations.webhooks.list.events.label') }}
                              </label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <b-form-checkbox
                                value="true"
                                unchecked-value="false"
                                name="ban.add"
                                class="text-code"
                                :class="'collector-'+webhook.id"
                                :checked="webhook.events.includes('ban.add')"
                              >
                                ban.add
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-3 col-sm-12">
                          <button class="btn btn-block btn-outline-danger" v-on:click="webhookDelete(webhook.id)" :disabled="savingInProgress" :class="{'disabled': savingInProgress}">
                            <i class="fad fa-trash-alt" />
                            {{ $t('banmanager.view.integrations.webhooks.list.delete') }}
                          </button>
                        </div>
                        <div class="col-lg-3 col-sm-12">
                          <button class="btn btn-block btn-outline-info" v-on:click="webhookReset(webhook.id)" :disabled="savingInProgress" :class="{'disabled': savingInProgress}">
                            <i class="fad fa-undo-alt" />
                            {{ $t('banmanager.view.integrations.webhooks.list.reset.button') }}
                          </button>
                        </div>
                        <div class="col-lg-3 col-sm-12">
                          <button class="btn btn-block btn-primary" v-on:click="webhookStrings(webhook.id)">
                            <i class="fad fa-comment-edit"/>
                            {{ $t('banmanager.view.integrations.webhooks.list.strings.button') }}
                          </button>
                        </div>
                        <div class="col-lg-3 col-sm-12 pull-right">
                          <button class="btn btn-block btn-success" v-on:click="webhookEventsSave(webhook.id)" :disabled="savingInProgress" :class="{'disabled': savingInProgress}">
                            <template v-if="savingInProgress">
                              <orbit-spinner
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                            </template>
                            <template v-else>
                              <i class="fad fa-save" />
                              {{ $t('banmanager.view.integrations.webhooks.list.save.button') }}
                            </template>
                          </button>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col justify-content-center">
              <div  class="info-component text-center align-middle mt-auto mb-auto">
                <div class="row" style="margin-top: 20px;">
                  <div class="col-lg-12">
                    <h3 v-b-tooltip.hover title="Wow such empty 👻">{{$t('banmanager.view.integrations.webhooks.empty.title')}}</h3>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col">
                    <button class="btn btn-primary btn-lg text-uppercase" :disabled="creationInProgress ? true : false" v-on:click="toggleWebhookModal()">
                      <h5 class="mb-0">
                        <i class="fad fa-plus" />
                        {{$t('banmanager.view.integrations.webhooks.empty.button')}}
                      </h5>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="grants.length">
            <hr class="mt-4">
            <h3>{{$t('banmanager.view.integrations.grants.title')}}</h3>
            <div role="tablist">
              <b-card no-body class="shadow-none settings-collapse" v-for="grant in grants" :key="grant.id">
                <a v-b-toggle="grant.id" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <div class="d-inline-block">
                      <h3 class="m-0">
                        {{grant.application.name}}
                        <small class="ml-2 text-muted text-code">
                          {{ grant.application.id }}
                        </small>
                      </h3>
                    </div>
                    <div class="d-inline-block float-right bv-d-md-down-none">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>

                <!-- General -->
                <b-collapse :id="grant.id" accordion="grants" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-lg-6 col-sm-12">
                          <label>{{$t('banmanager.view.integrations.grants.list.id')}}</label>
                          <h5 class="text-code"><CopyableText :text="grant.id"></CopyableText></h5>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <label>{{$t('banmanager.view.integrations.grants.list.created_at')}}</label>
                          <h5>{{ $d(parseDate(grant.created_at), 'datetime', getDTLocale()) }}</h5>
                        </div>
                      </div>
                      <div class="row mt-2 justify-content-end">
                        <div class="col-lg-3 col-sm-12">
                          <button class="btn btn-block btn-outline-danger" v-on:click="grantDelete(grant.id)" :disabled="savingInProgress" :class="{'disabled': savingInProgress}">
                            {{ $t('banmanager.view.integrations.grants.list.delete.button') }}
                          </button>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </template>
        </div>
        <div class="col-lg-3 col-sm-12">
          <button class="btn btn-primary btn-block" v-on:click="toggleWebhookModal()" :disabled="creationInProgress ? true : false">
            <i class="fad fa-plus"/>
            {{$t('banmanager.view.integrations.webhooks.empty.button')}}
          </button>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Templates -->
    <b-modal
        ref="webhookTemplatesModal"
        :title="$t('banmanager.view.integrations.webhooks.templates.title')"
        title-class="font-18"
        hide-footer
        size="lg"
    >
      <div class="row justify-content-center">
        <div class="col-lg-12 col-sm-12 mt-1" v-for="(value, key) in featuredWebhookTemplates" :key="key">
          <div class="form-group">
            <h5 class="text-code p-0">
              {{key}}
            </h5>
            <input
                v-model="featuredWebhookTemplates[key]"
                type="text"
                class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-2">
        <div class="col-lg-4 col-sm-12">
          <button class="btn btn-block btn-success" v-on:click="webhookTemplatesSave(featuredWebhookId)" :disabled="savingInProgress" :class="{'disabled': savingInProgress}">
            <template v-if="savingInProgress">
              <orbit-spinner
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
            </template>
            <template v-else>
              <i class="fad fa-save" />
              {{ $t('banmanager.view.integrations.webhooks.list.save.button') }}
            </template>
          </button>
        </div>
      </div>
    </b-modal>
    <!-- New WebHook modal -->
    <b-modal
        ref="addWebHookModal"
        :title="$t('banmanager.view.integrations.webhooks.new.title')"
        title-class="font-18"
        hide-footer
        size="lg"
    >
      <div class="row justify-content-center">
        <div class="col-lg-8 col-sm-12">
          <!-- URL -->
          <div class="form-group">
            <label for="webhookFormURL">
              {{ $t('banmanager.view.integrations.webhooks.new.url.label') }}
            </label>
            <input
                id="webhookFormURL"
                v-model="webhookForm.url"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': $v.webhookForm.url.$error }"
                v-on:change="webhookFormUrlChanged()"
                v-on:input="webhookFormUrlChanged()"
            />
            <div v-if="$v.webhookForm.url.$error" class="invalid-feedback">
              <span v-if="!$v.webhookForm.url.required">{{ $t('banmanager.view.integrations.webhooks.new.url.errors.required') }}</span>
              <span v-if="!$v.webhookForm.url.url">{{ $t('banmanager.view.integrations.webhooks.new.url.errors.url') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" v-if="webhookForm.flavor !== null">
        <div class="col-lg-8 col-sm-12">
          <!-- URL -->
          <div class="form-group">
            <label for="webhookFormFlavor">
              {{ $t('banmanager.view.integrations.webhooks.new.flavor.label') }}
              <small>
                {{ $t('banmanager.view.integrations.webhooks.new.flavor.description') }}
              </small>
            </label>
            <select id="webhookFormFlavor"
                    v-model="webhookForm.flavor"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.webhookForm.flavor.$error }">
              <option :value="WebHookFlavor.CFTOOLS">
                CFTools Cloud (Hephaistos v1)
              </option>
              <option :value="WebHookFlavor.DISCORD">
                Discord WebHook
              </option>
            </select>
            <div v-if="$v.webhookForm.url.$error" class="invalid-feedback">
              <span v-if="!$v.webhookForm.url.required">{{ $t('banmanager.view.integrations.webhooks.new.flavor.errors.required') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" v-if="webhookForm.url && webhookForm.flavor !== null">
        <div class="col-lg-8 col-sm-12">
          <button class="btn btn-primary btn-block" v-on:click="newWebHook()" :class="{'disabled': creationInProgress}">
            <orbit-spinner
                v-if="creationInProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!creationInProgress">
              <i class="fad fa-plus"/>
              {{ $t('banmanager.view.integrations.webhooks.new.button') }}
            </template>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
